// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Reset the margin and padding */
html, body {
    margin: 0;
    padding: 0;
    font-family: 'Helvetica', sans-serif;
  }
  
  /* Layout structure */
  .layout {
    display: flex;
    height: 100vh;
  }
  
  /* Sidebar styling */
  .sidebar {
    width: 13%;
    background-color: #212121; /* Black for sidebar background */
    padding: 20px;
    color: white;
  }
  
  .sidebar h2 {
    margin-bottom: 30px;
  }
  
  /* Sidebar navigation items */
  .sidebar .nav-item {
    display: block;
    padding: 15px;
    text-decoration: none;
    background-color: #464344;
    color: white;
    font-size: 24px;
    margin-bottom: 10px;
    border-radius: 2px;
    transition: background-color 0.3s ease; /* Smooth background transition */
  }
  
  /* Active link style */
  .sidebar .active {
    background-color: #752938; /* Red active link background */
  }
  
  /* Main content styling */
  .content {
    width: 100%;
    padding: 20px;
    background-color: rgb(57, 21, 54); /* Main content background */
  }
  `, "",{"version":3,"sources":["webpack://./src/Layout.css"],"names":[],"mappings":"AAAA,iCAAiC;AACjC;IACI,SAAS;IACT,UAAU;IACV,oCAAoC;EACtC;;EAEA,qBAAqB;EACrB;IACE,aAAa;IACb,aAAa;EACf;;EAEA,oBAAoB;EACpB;IACE,UAAU;IACV,yBAAyB,EAAE,iCAAiC;IAC5D,aAAa;IACb,YAAY;EACd;;EAEA;IACE,mBAAmB;EACrB;;EAEA,6BAA6B;EAC7B;IACE,cAAc;IACd,aAAa;IACb,qBAAqB;IACrB,yBAAyB;IACzB,YAAY;IACZ,eAAe;IACf,mBAAmB;IACnB,kBAAkB;IAClB,sCAAsC,EAAE,iCAAiC;EAC3E;;EAEA,sBAAsB;EACtB;IACE,yBAAyB,EAAE,+BAA+B;EAC5D;;EAEA,yBAAyB;EACzB;IACE,WAAW;IACX,aAAa;IACb,iCAAiC,EAAE,4BAA4B;EACjE","sourcesContent":["/* Reset the margin and padding */\nhtml, body {\n    margin: 0;\n    padding: 0;\n    font-family: 'Helvetica', sans-serif;\n  }\n  \n  /* Layout structure */\n  .layout {\n    display: flex;\n    height: 100vh;\n  }\n  \n  /* Sidebar styling */\n  .sidebar {\n    width: 13%;\n    background-color: #212121; /* Black for sidebar background */\n    padding: 20px;\n    color: white;\n  }\n  \n  .sidebar h2 {\n    margin-bottom: 30px;\n  }\n  \n  /* Sidebar navigation items */\n  .sidebar .nav-item {\n    display: block;\n    padding: 15px;\n    text-decoration: none;\n    background-color: #464344;\n    color: white;\n    font-size: 24px;\n    margin-bottom: 10px;\n    border-radius: 2px;\n    transition: background-color 0.3s ease; /* Smooth background transition */\n  }\n  \n  /* Active link style */\n  .sidebar .active {\n    background-color: #752938; /* Red active link background */\n  }\n  \n  /* Main content styling */\n  .content {\n    width: 100%;\n    padding: 20px;\n    background-color: rgb(57, 21, 54); /* Main content background */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
