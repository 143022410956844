// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Links.css */
.links-container {
  text-align: center;
  padding: 50px 0;
  color: white;
}

h1 {
  font-family: 'Helvetica', sans-serif;
  font-size: 36px;
  margin-bottom: 30px;
}

.links-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.link-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 30px;
  border-radius: 5px;
  background-color: #4a4a4a;
  color: white;
  font-size: 18px;
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.link-button:hover {
  background-color: #79162F; /* Red color on hover */
  transform: translateY(-3px); /* Slight lift effect */
}

.link-button svg {
  margin-right: 10px; /* Spacing between icon and text */
}

.links-grid .link-button {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: calc(25% - 20px); /* Adjust the size of the buttons based on the container */
  max-width: 200px;
}

@media (max-width: 768px) {
  .links-grid .link-button {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: calc(50% - 20px);
  }
}
`, "",{"version":3,"sources":["webpack://./src/Links.css"],"names":[],"mappings":"AAAA,cAAc;AACd;EACE,kBAAkB;EAClB,eAAe;EACf,YAAY;AACd;;AAEA;EACE,oCAAoC;EACpC,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,uBAAuB;EACvB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,kBAAkB;EAClB,yBAAyB;EACzB,YAAY;EACZ,eAAe;EACf,qBAAqB;EACrB,2DAA2D;AAC7D;;AAEA;EACE,yBAAyB,EAAE,uBAAuB;EAClD,2BAA2B,EAAE,uBAAuB;AACtD;;AAEA;EACE,kBAAkB,EAAE,kCAAkC;AACxD;;AAEA;EACE,YAA0B;EAA1B,cAA0B;EAA1B,4BAA0B,EAAE,0DAA0D;EACtF,gBAAgB;AAClB;;AAEA;EACE;IACE,YAA0B;IAA1B,cAA0B;IAA1B,4BAA0B;EAC5B;AACF","sourcesContent":["/* Links.css */\n.links-container {\n  text-align: center;\n  padding: 50px 0;\n  color: white;\n}\n\nh1 {\n  font-family: 'Helvetica', sans-serif;\n  font-size: 36px;\n  margin-bottom: 30px;\n}\n\n.links-grid {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n  gap: 20px;\n}\n\n.link-button {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 15px 30px;\n  border-radius: 5px;\n  background-color: #4a4a4a;\n  color: white;\n  font-size: 18px;\n  text-decoration: none;\n  transition: background-color 0.3s ease, transform 0.3s ease;\n}\n\n.link-button:hover {\n  background-color: #79162F; /* Red color on hover */\n  transform: translateY(-3px); /* Slight lift effect */\n}\n\n.link-button svg {\n  margin-right: 10px; /* Spacing between icon and text */\n}\n\n.links-grid .link-button {\n  flex: 1 1 calc(25% - 20px); /* Adjust the size of the buttons based on the container */\n  max-width: 200px;\n}\n\n@media (max-width: 768px) {\n  .links-grid .link-button {\n    flex: 1 1 calc(50% - 20px);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
