import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Layout from './Layout';
import Links from './Links';
import Gallery from './Gallery';
import Home from './Home'; // Import Home component

function App() {
  // Custom hook to send pageviews to Google Analytics
  function usePageViews() {
    const location = useLocation();
    
    useEffect(() => {
      if (window.gtag) {
        // Send a pageview to Google Analytics
        window.gtag('config', 'G-MVY66MNQ08', {
          page_path: location.pathname,
        });
      }
    }, [location]);
  }

  usePageViews();

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="links" element={<Links />} />
        <Route path="gallery" element={<Gallery />} />
      </Route>
    </Routes>
  );
}

export default function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}
