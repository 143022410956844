import React from 'react';
import './Links.css';

const links = [
  { name: 'Bluesky', url: 'https://bsky.app/profile/chaptude.bsky.social' },
  { name: 'Discord Server', url: 'https://discord.gg/k6dWSJc3wN' },
  { name: 'FurAffinity', url: 'https://www.furaffinity.net/user/comediancat/' },
  { name: 'DeviantArt', url: 'https://www.deviantart.com/chaptude' },
  { name: 'Itaku', url: 'https://itaku.ee/profile/chaptude' },
  { name: 'Telegram Channel', url: 'https://t.me/chaptude' },
  { name: 'Twitter', url: 'https://twitter.com/Chaptude'},
  { name: 'Weasly', url: 'https://www.weasyl.com/~chaptude' }
]

const Links = () => {
  return (
    <div className="links-container">
      <h1>Media Links</h1>
      <div className="links-grid">
        {links.map((link, index) => (
          <a key={index} href={link.url} target="_blank" rel="noopener noreferrer" className="link-button">
            {link.icon} {link.name}
          </a>
        ))}
      </div>
    </div>
  );
};

export default Links;
