import React from 'react';

const Gallery = () => {
  return (
    <div>
      <h1 style={{ color: 'white' }}>Under construction</h1>
    </div>
  );
};

export default Gallery;
